import Collapsible from "@lunaweb/vega-reactor-js/src/patterns/Collapsible";

export default class Dropdown extends Collapsible {

  static settings = {
    control: '.js-dropdown-control',
    target: '.js-dropdown-popover',
  };

  constructor (element, options = {}) {
    super(element, {
      ...Dropdown.settings,
      ...options
    });
  }
}
