import Dropdown from "./Dropdown";

export default class DropdownChoice extends Dropdown {

  static settings = {
    control: '.js-dropdown-choice-control',
    target: '.js-dropdown-choice-popover',
    cancel: '.js-dropdown-choice-cancel',
    confirm: '.js-dropdown-choice-confirm',
    reset: '.js-dropdown-choice-reset',
    label: '.js-dropdown-choice-label',
    i18n: {
      label_empty: 'Sélectionnez une ou plusieurs options',
      label_count: '%selected% sur %length% %descriptor%',
      singular: 'sélectionné',
      plural: 'sélectionnés',
    }
  };

  #cancel;
  #confirm;
  #reset;
  #label;
  #inputs;
  #backup = [];

  #handleChange;
  #handleConfirmClick;
  #handleCancelClick;
  #handleResetClick;

  constructor (element, options = {}) {
    super(element, {
      ...DropdownChoice.settings,
      ...options
    });

    this.#cancel = this.getNode(this.settings.cancel);
    this.#confirm = this.getNode(this.settings.confirm);
    this.#reset = this.getNode(this.settings.reset);
    this.#label = this.getNode(this.settings.label);
    this.#inputs = this.target.querySelectorAll('input');

    this.#handleChange = this._handleChange.bind(this);
    this.#handleCancelClick = this._handleCancelClick.bind(this);
    this.#handleConfirmClick = this._handleConfirmClick.bind(this);
    this.#handleResetClick = this._handleResetClick.bind(this);
  }

  get backup () {
    return this.#backup;
  }

  get selected () {
    return Array.from(this.#inputs).filter((element) => { return element.type == "radio" || element.type == "checkbox" ? element.checked : element.value });
  }

  _handleChange (e) {
    const selected = this.selected;
    let text = this.settings.i18n.label_count;

    if (selected.length === 0) {
      text = this.settings.i18n.label_empty;
    } else if (selected.length === 1) {
      // text = text.replace('%descriptor%', this.settings.i18n.singular);
      text = this.target.querySelector(`[for="${ selected[0].id }"]`).innerText;
    } else {
      text = text.replace('%descriptor%', this.settings.i18n.plural);
    }

    text = text.replace('%selected%', selected.length);
    text = text.replace('%length%', this.#inputs.length);

    this.#label.innerHTML = text;
  }

  _handleCancelClick (e) {
    this.cancel();
    this.#handleChange();
    this.close();
  }

  _handleConfirmClick (e) {
    console.log('apply');
    this.confirm();
    this.#handleChange();
    this.close();
  }

  _handleResetClick (e) {
    this.reset();
    if (!this.#confirm) {
      this.#handleChange();
    }
  }

  cancel () {
    this.#inputs.forEach(($input) => {
      if ($input.type == "radio" || $input.type == "checkbox") {
        $input.checked = this.#backup[$input.id];
      } else {
        $input.value = this.#backup[$input.id];
      }
    });
  }

  confirm () {
    const backup = [];
    this.#inputs.forEach(($input) => {
      if ($input.type == "radio" || $input.type == "checkbox") {
        backup[$input.id] = $input.checked;
      } else {
        backup[$input.id] = $input.value;
      }
      this.fire('cancel', this);
    });

    this.#backup = backup;
    console.log(backup);
    this.fire('confirm', this);
  }

  reset () {
    this.#inputs.forEach(($input) => {
      if ($input.type == "radio" || $input.type == "checkbox") {
        $input.checked = false;
      } else {
        $input.value = null;
      }
    });
    this.fire('reset', this);
  }

  close () {
    this.cancel();
    super.close();
  }

  mount () {
    super.mount();

    this.#inputs.forEach(($input) => {
      this.#backup[$input.id] = $input.checked;
    });

    this.#handleChange();

    if (this.#cancel) {
      this.#cancel.addEventListener('click', this.#handleCancelClick);
    }

    if (this.#confirm) {
      this.#confirm.addEventListener('click', this.#handleConfirmClick);
      } else {
      this.target.addEventListener('change', this.#handleChange);
    }

    if (this.#reset) {
      this.#reset.addEventListener('click', this.#handleResetClick);
    }
  }
}
