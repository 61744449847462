import Dropdown from './Dropdown';
import DropdownChoice from './DropdownChoice';

window.addEventListener('DOMContentLoaded', () => {
  const $dropdowns = document.querySelectorAll('.js-dropdown');
  const dropdowns = [];

  if ($dropdowns.length) {
    $dropdowns.forEach(($dropdown) => {
      // Used for demo
      let options = {};

      const dropdown = new Dropdown($dropdown, options);
      dropdowns.push(dropdown);
      dropdown.mount();
    });
  }


  const $dropdownsChoice = document.querySelectorAll('.js-dropdown-choice');
  const dropdownsChoice = [];

  if ($dropdownsChoice.length) {
    $dropdownsChoice.forEach(($dropdownChoice) => {
      // Used for demo
      let options = {};
      const $firstInput = $dropdownChoice.querySelector('input');
      if ($firstInput && $firstInput.type == "radio") {
        options = {
          i18n: {
            label_empty: 'Sélectionnez une option',
          }
        }
      }

      const dropdownChoice = new DropdownChoice($dropdownChoice, options);
      dropdownsChoice.push(dropdownChoice);
      dropdownChoice.mount();
    });
  }
});
